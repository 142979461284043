<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view/>
    </div>
  </a-config-provider>
</template>
<script>
  // import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
  import {domTitle, setDocumentTitle} from '@/utils/domUtil'
  import enquireScreen from '@/utils/device'
  import {i18nRender} from '@/locales'

  export default {
    data () {
      return {
        // locale: zhCN,
      }
    },
    computed: {
      locale () {
        // 只是为了切换语言时，更新标题
        const { title } = this.$route.meta
        title && (setDocumentTitle(`${i18nRender(title)} - ${domTitle}`))

        return this.$i18n.getLocaleMessage(this.$store.getters.lang).antLocale
      }
    },
    created () {
      let that = this
      enquireScreen(deviceType => {
        // tablet
        if (deviceType === 0) {
          that.$store.commit('TOGGLE_DEVICE', 'mobile')
          that.$store.dispatch('setSidebar', false)
        }
        // mobile
        else if (deviceType === 1) {
          that.$store.commit('TOGGLE_DEVICE', 'mobile')
          that.$store.dispatch('setSidebar', false)
        }
        else {
          that.$store.commit('TOGGLE_DEVICE', 'desktop')
          that.$store.dispatch('setSidebar', true)
        }

      })
    }
  }
</script>
<style>
  #app {
    height: 100%;
  }
</style>