<template>
  <page-header-wrapper>
    <router-view />
  </page-header-wrapper>
</template>

<script>

  export default {
    name: 'PageView'
  }
</script>
