<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
</template>

<script>
  import {GlobalFooter} from '@ant-design-vue/pro-layout'

  export default {
  name: 'ProGlobalFooter',
  components: {
    GlobalFooter
  }
}
</script>
<style lang="less" scoped>
  .footer {
    padding: 0 16px;
    margin: 48px 0 24px;
    text-align: center;
    .copyright {
      color: rgba(0, 0, 0, .45);
      font-size: 14px;
    }
  }
</style>
