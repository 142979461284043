import {deleteAction, getAction, postAction, putAction} from '@/api/manage'
import Vue from 'vue'
import {UI_CACHE_DB_DICT_DATA} from "@/store/mutation-types"

//角色管理
const addRole = (params)=>postAction("/sys/role/add",params);
const editRole = (params)=>putAction("/sys/role/edit",params);
const checkRoleCode = (params)=>getAction("/sys/role/checkRoleCode",params);
const queryall = (params)=>getAction("/sys/role/queryall",params);

//用户管理
const addUser = (params)=>postAction("/sys/user/add",params);
const editUser = (params)=>putAction("/sys/user/edit",params);
const queryUserRole = (params)=>getAction("/sys/user/queryUserRole",params);
const getUserList = (params)=>getAction("/sys/user/list",params);
const frozenBatch = (params)=>putAction("/sys/user/frozenBatch",params);
//验证用户是否存在
const checkOnlyUser = (params)=>getAction("/sys/user/checkOnlyUser",params);
//改变密码
const changePassword = (params)=>putAction("/sys/user/changePassword",params);

//权限管理
const addPermission= (params)=>postAction("/sys/permission/add",params);
const editPermission= (params)=>putAction("/sys/permission/edit",params);
const getPermissionList = (params)=>getAction("/sys/permission/list",params);
/*update_begin author:wuxianquan date:20190908 for:添加查询一级菜单和子菜单查询api */
const getSystemMenuList = (params)=>getAction("/sys/permission/getSystemMenuList",params);
const getSystemSubmenu = (params)=>getAction("/sys/permission/getSystemSubmenu",params);
const getSystemSubmenuBatch = (params) => getAction('/sys/permission/getSystemSubmenuBatch', params)

const queryTreeList = (params)=>getAction("/sys/permission/queryTreeList",params);
const queryTreeListForRole = (params)=>getAction("/sys/role/queryTreeList",params);
const queryListAsync = (params)=>getAction("/sys/permission/queryListAsync",params);
const queryRolePermission = (params)=>getAction("/sys/permission/queryRolePermission",params);
const saveRolePermission = (params)=>postAction("/sys/permission/saveRolePermission",params);
const queryPermissionsByUser = (params)=>getAction("/sys/permission/getUserPermissionByToken",params);
const loadAllRoleIds = (params)=>getAction("/sys/permission/loadAllRoleIds",params);
const getPermissionRuleList = (params)=>getAction("/sys/permission/getPermRuleListByPermId",params);
const queryPermissionRule = (params)=>getAction("/sys/permission/queryPermissionRule",params);

//日志管理
//const getLogList = (params)=>getAction("/sys/log/list",params);
const deleteLog = (params)=>deleteAction("/sys/log/delete",params);
const deleteLogList = (params)=>deleteAction("/sys/log/deleteBatch",params);

//数据字典
const addDict = (params)=>postAction("/sys/dict/add",params);
const editDict = (params)=>putAction("/sys/dict/edit",params);
const treeList = (params)=>getAction("/sys/dict/treeList",params);
const addDictItem = (params)=>postAction("/sys/dictItem/add",params);
const editDictItem = (params)=>putAction("/sys/dictItem/edit",params);

//字典标签专用（通过code获取字典数组）
export const ajaxGetDictItems = (code, params)=>getAction(`/sys/dict/getDictItems/${code}`,params);
//从缓存中获取字典配置
function getDictItemsFromCache(dictCode) {
  const data = Vue.ls.get(UI_CACHE_DB_DICT_DATA)
  if (data) {
    const dictItems = data[dictCode];
    if (dictItems) {
      return dictItems;
    }
  }
}

//获取系统访问量
const getLoginfo = (params)=>getAction("/sys/loginfo",params);
const getVisitInfo = (params)=>getAction("/sys/visitInfo",params);

// 重复校验
const duplicateCheck = (params)=>getAction("/sys/duplicate/check",params);


// 商户服务
const queryServiveInfo = (params) => getAction("/sys/service/serviceInfo",params);
const changeLoginPassword = (params) => getAction("/sys/service/modify/loginPassword",params);
const changePayPassword = (params) => getAction("/sys/service/modify/paymentPassword",params);
const queryMerchantBalance = (params) => getAction("/sys/agentpay/getMerchantBalance",params);
const submintAgentpay = (params) => getAction("/sys/agentpay/submintAgentpay",params);
const queryTradeList = (params) => getAction("/sys/trade/getTradeList",params);

const addMerchant = (params) => getAction("/sys/merchant/addMerchant",params);
const editMerchant = (params) => getAction("/sys/merchant/editMerchant",params);
const activateMail = (params) => getAction("/sys/merchant/activateMail",params);
const addMerchantService = (params) => getAction("/sys/merchant/addMerchantService",params);
const editMerchantService = (params) => getAction("/sys/merchant/editMerchantService",params);
const processAgentpay = (params) => getAction("/sys/agentpay/process",params);
const confirmAgent = (params) => getAction("/sys/agentpay/confirmAgent",params);
const collectionUSDT = (params) => getAction("/sys/wallet/collection",params);

const addChannel = (params) => getAction("/sys/channel/addChannel",params);
const editChannel = (params) => getAction("/sys/channel/editChannel",params);
const channelList = (params) => getAction("/sys/channel/channelAllList",params);

const addParentAccount = (params) => getAction("/sys/huobi/addParentAccount",params);
const editParentAccount = (params) => getAction("/sys/huobi/editParentAccount",params);

const parentAccountList = (params) => getAction("/sys/huobi/parentAccountAllList",params);
const addSubUser = (params) => getAction("/sys/huobi/addSubUser",params);

export {
  collectionUSDT,
  addMerchantService,
  editMerchantService,
  processAgentpay,
  addRole,
  editRole,
  checkRoleCode,
  addUser,
  editUser,
  queryUserRole,
  getUserList,
  queryall,
  frozenBatch,
  checkOnlyUser,
  changePassword,
  getPermissionList,
  addPermission,
  editPermission,
  queryTreeList,
  queryListAsync,
  queryRolePermission,
  saveRolePermission,
  queryPermissionsByUser,
  loadAllRoleIds,
  getPermissionRuleList,
  queryPermissionRule,
  deleteLog,
  deleteLogList,
  addDict,
  editDict,
  treeList,
  addDictItem,
  editDictItem,
  getLoginfo,
  getVisitInfo,
  duplicateCheck,
  queryTreeListForRole,
  getSystemMenuList,
  getSystemSubmenu,
  getSystemSubmenuBatch,
  getDictItemsFromCache,
  queryServiveInfo,
  changeLoginPassword,
  changePayPassword,
  queryMerchantBalance,
  submintAgentpay,
  queryTradeList,
  addMerchant,
  editMerchant,
  addChannel,
  editChannel,
  channelList,
  activateMail,
  addParentAccount,
  editParentAccount,
  parentAccountList,
  addSubUser,
  confirmAgent
}



